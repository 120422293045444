import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/root-saga'
import loginReducer from './reducer/loginReducer'
import userReducer from './reducer/userReducer'

const rootReducer = combineReducers({
 user: loginReducer,
 addUser:userReducer
 
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)

export default store