import React from "react";
import "./../App.css";

//Return Footer HTML
const Footer = () => {
  return (
    <div className="footer">
      Copyright 2023 Bunzl App - All Rights Reserved.
    </div>
  );
};

export default Footer;
