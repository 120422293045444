import React, { useRef, useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

const Notifications = ({ notifications, onNotificationClick }) => {
  const toast = useRef(null);
  const [shownNotifications, setShownNotifications] = useState([]);

  useEffect(() => {
    // Display new notifications
    const newNotifications = notifications.filter(
      (item) => !shownNotifications.includes(item.Id)
    );

    newNotifications.forEach((item) => {
      showToast(item);
      setShownNotifications((prev) => [...prev, item.Id]);
    });
  }, [notifications]);

  const showToast = (item) => {
    toast.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
        <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
          <div className="text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <div className="font-bold text-xl my-3">{item?.NotificationText}</div>
          </div>
          <div className="flex gap-2">
            <Button onClick={(e) => {
              onNotificationClick(e, item?.Id);
            }} type="button" label="OK"/>
          </div>
        </div>
      )
    });
  };

  return <Toast ref={toast} />;
};

export default Notifications;
