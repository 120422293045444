import React, { useState, useEffect, useRef } from "react";
import Storage from "../utils/storage";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import axios from "axios";
import Config from "./../Config";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MAX_SKIPS } from "../constants/ApiConstants";
import { FileUpload } from "primereact/fileupload";
import ItemStatuses from "../components/ItemStatuses";
import Notifications from "../components/Notification";
import { getPFAData, handleSubmit, getCertificates,fetchCertificatePDF,
helpFAQPDFs,helpAttributePDFs,TNCPDFs, getNotificationPopup,
handleOkSubmit,handleSkips,viewCertificatePDFs,viewTestCertificatePDFs,
getCertificatesPDFs,uploadCertificates } from "../services/DashboardService";
import {  isInternalEnricher, isExternalEnricher,isSupplier } from "../utils/roleValidator";
import {
  deleteStoredData,
  httpPOST,
  getAccessToken,
  setRefreshToken,
} from "../utils/http";
import { showErrorToast, showSuccessToast, showWarningToast, showErrorToastNullable } from "../services/ToastService";

function Dashboard(props) {
  const token = getAccessToken();
  const [isPFASSurveyComplete, setIsPFASSurveyComplete] = useState(false); 
  const [activeIndex, setActiveIndex] = useState(0); 
  const [isPFASEnabled, setIsPFASEnabled] = useState(null); // State to manage PFAS option
  const [isPFASTestEnabled, setIsPFASTestEnabled] = useState(null); // State to manage PFAS test option
  const [skipCount, setSkipCount] = useState(0);
  const supplierId = Storage.get("selectedSuppliers");
  const {selectedSuppliers: supplierIds } = props;
  const toast = useRef(null);
  const toastBC = useRef(null);
  const navigate = useNavigate();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const suppliersgroup = Storage.get("suppliers");
  const suppliergroupname = suppliersgroup?.GroupName;
  const suppliers = suppliersgroup?.Suppliers;
  const manufacturers = Storage.get("manufacturers");
  const [singleSelectedSuppliers, setSingleSelectedSuppliers] = useState(suppliers?.length > 0 ? suppliers[0] : null);
  const [singleSelectedManufacturer, setSingleSelectedManufacturer] = useState(manufacturers?.length > 0 ? manufacturers[0] : null);
  const [certificates, setCertificates] = useState([]);
  const [testCertificates, setTestCertificates] = useState([]);
  const[fileLocation,setFileLocation] = useState();
  const { surveyPassed, setSurveyPassed } = props;
  const [disabledSkip, setDisabledSkip] = useState(false);
  const [showSkipsRemaining, setShowSkipsRemaining] = useState(true);
  const [certificatesUploaded, setCertificatesUploaded] = useState([]);
  const [testCertificatesUploaded, setTestCertificatesUploaded] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const UserId = Storage.get("user");
  const { survey } = props;
  const [skipped, submitted] = survey;
  const isManufacturer = !(suppliers?.length > 0) && manufacturers?.length > 0;
  const [singleSelected, setSingleSelected] = useState(isManufacturer ? singleSelectedManufacturer : singleSelectedSuppliers);
  const idType = isManufacturer ? "manufacturer" : "supplier";
  const [isRunning, setIsRunning] = useState('');
  const [notificationId, setIsNotificationId] = useState('');
  const [notifications, setNotifications] = useState([]);
  const Role = Storage.get("Role");
  const onSupplierChange = (e) => {
    setSingleSelectedSuppliers(e.value);
  };
  const onManufacturerChange = (e) => {
    setSingleSelectedManufacturer(e.value);
  };
  if (singleSelectedSuppliers?.length !== 0) {
    Storage.set("singleSelectedSuppliers", singleSelectedSuppliers);
  } else {
    Storage.remove("singleSelectedSuppliers");
  }
  useEffect(() => {
    if (singleSelectedManufacturer?.length) {
      Storage.set('singleSelectedManufacturer', singleSelectedManufacturer);
    } else {
      Storage.remove('singleSelectedManufacturer');
    }
  }, [singleSelectedManufacturer]);

  useEffect(() => {
    setSingleSelected(isManufacturer ? singleSelectedManufacturer : singleSelectedSuppliers);
  }, [isManufacturer, singleSelectedManufacturer, singleSelectedSuppliers]);
  
  const certificatePDF = () => {
    fetchCertificatePDF(
      (response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response?.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      },
      (error) => {
      }
    );
  };
  
  const helpFAQPDF = () => {
    helpFAQPDFs((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response?.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      },
       (error) => {
      });
  };
 
  const helpAttributePDF = () => {
    helpAttributePDFs(
      (response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response?.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      },
      (error) => {
      });
  };
 
  const TNCPDF = () => {
    TNCPDFs(
      (response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response?.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      },
      (error) => {
      });
  };
 
  const notificationPopup = async () => {
    getNotificationPopup(UserId,token,(res) =>{
      if(res?.status === 200 && res?.data?.length > 0) {
        setNotifications(res.data);
      }},
     async(e) => {
      // Handle error
    })
  };
     
      const onSubmit=(e, id)=>{
        let outerDiv = e.target.closest(".p-toast-message-content");;
        let close = outerDiv.querySelector("button.p-toast-icon-close.p-link");
        close.click();    
        handleOkSubmit(id,token,(res) => {
        },
        (e) => {});
       }

  useEffect(() => {
    getPFADatas();
    getCertificates();
  }, [singleSelected]);

  const getPFADatas = () => {
    getPFAData(singleSelected?.id, token, idType, 
      (res) => {
        if(res?.data?.isPFASEnabled != null)
        {
          setIsPFASSurveyComplete(true);
          setActiveIndex(null);
        } else {
          setIsPFASSurveyComplete(false);
        }
        setIsPFASEnabled(res?.data?.isPFASEnabled);
        setIsPFASTestEnabled(res?.data?.isPFASTestEnabled);
       submitted[singleSelected?.id] = (res?.data?.isPFASEnabled != null && res?.data?.isPFASTestEnabled != null);
        if (res?.data?.skipCount != undefined) { 
          setSkipCount(res?.data?.skipCount); 
        } else {
          setSkipCount(0);
        }
       setShowSkipsRemaining(!submitted[singleSelected?.id] && singleSelected != undefined);
        setSurveyPassed(skipped[singleSelected?.id] || submitted[singleSelected?.id]);
        setDisabledSkip(!(MAX_SKIPS - skipCount > 0) || submitted[singleSelected?.id] || skipped[singleSelected?.id]);
      },
       async (e) => {
        if (e.response?.status == 401) {
          showErrorToastNullable(toast, "Session Timeout! Please Login again");

          await sleep(5000);
          deleteStoredData();
          navigate("/");
        }

        showErrorToastNullable(toast, e.response?.data?.Message);
      });
  };
  const handleSave = async () => {
    try {
      if ((isPFASEnabled === 0 || isPFASEnabled === 2) && certificates?.length) {
        await uploadCertificate(1);
      }
      if (isPFASTestEnabled === true && testCertificates?.length) {
        await uploadCertificate(2);
      }
    } catch (e) {
      if (e.response?.status == 401) {
        showErrorToastNullable(toast, "Session Timeout! Please Login again");
      }
      showErrorToastNullable(toast, e.response?.data?.Message);
      return;
    }
    
    let emptyProduct = {
      SupplierId: singleSelectedSuppliers?.id,
      isPFASEnabled: isPFASEnabled,
      isPFASTestEnabled: isPFASTestEnabled,
      pfasLastUpdatedBy: UserId,
      pfasLastUpdatedDate: new Date().toISOString(),
      Id: singleSelected?.id,
      CreatedBy: UserId,
      CreatedDate: new Date().toISOString(),
      ModifiedBy: UserId,
      ModifiedDate: new Date().toISOString(),
      ManufacturerId: singleSelectedManufacturer?.id,
    };

    handleSubmit(emptyProduct, singleSelected?.id, token, idType, 
      (res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, "Survey submitted successfully");
          if(isPFASEnabled === true){
            notificationPopup();
            }
          getPFADatas();
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      },
      async (e) => {
        if (e.response?.status == 401) {
          showErrorToastNullable(toast, "Session Timeout! Please Login again");
          
          await sleep(5000);
          deleteStoredData();
          navigate("/");
        }
       else if (e?.response?.status === 422) {
          showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
        } else {
          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });
  };
 
  useEffect(() => {
    setDisabledSkip(!(MAX_SKIPS - skipCount > 0) || submitted[singleSelected?.id] || skipped[singleSelected?.id]);
    setShowSkipsRemaining(!submitted[singleSelected?.id] && singleSelected != undefined);
  }, [skipCount]);

  const handleSkip = () => {
    const count = skipCount + 1;
    skipped[singleSelected?.id] = MAX_SKIPS - skipCount > 0;
    setDisabledSkip(true);
    setSurveyPassed(skipped[singleSelected?.id] || submitted[singleSelected?.id]);
    setSkipCount(prevCount => prevCount + 1);
    let emptyProduct = {
      SupplierId: singleSelectedSuppliers?.id,
      isPFASEnabled: null,
      isPFASTestEnabled: null,
      pfasLastUpdatedBy: UserId,
      pfasLastUpdatedDate: new Date().toISOString(),
      Id: singleSelected?.id,
      CreatedBy: UserId,
      CreatedDate: new Date().toISOString(),
      ModifiedBy: UserId,
      ModifiedDate: new Date().toISOString(),
      skipCount: count,
      ManufacturerId: singleSelectedManufacturer?.id,
    }
   handleSkips(emptyProduct, singleSelected?.id, token, idType, 
    (res) => {
      if (res.status === 200) {
        showSuccessToast(toast, `Skipped. ${MAX_SKIPS - count} skips remaining`);
      }
    },
    (e) => {
      if (e.response?.status == 401) {
        showErrorToastNullable(toast, "Session Timeout! Please Login again");
      }
      showErrorToastNullable(toast, e.response?.data?.Message);
    })
  }
  
  const viewCertificate = (cert) => { 
    // Log the comma-separated string for debugging
   viewCertificatePDFs(cert.FileLocation,singleSelected?.id,token,(res) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([res?.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      },
      (e) => {
        showErrorToastNullable(toast, e.response?.data?.Message);
      })
    }
 
    const viewTestCertificate = (cert) => {
      // Log the comma-separated string for debugging
      viewTestCertificatePDFs(cert.FileLocation,singleSelected?.id,token,(res) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([res?.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);        },
        (e) => {
          showErrorToastNullable(toast, e.response?.data?.Message);
        })
      }
 
  const getCertificates = () => {
    getCertificatesPDFs(singleSelected?.id,token, idType, (res)=> {
        const certificatesData = res?.data.filter(item => item.FileType === 1);
            setCertificatesUploaded(certificatesData);
            const certificatesTestData = res?.data.filter(item => item.FileType === 2);
            setTestCertificatesUploaded(certificatesTestData);
           setFileLocation(res?.data?.FileLocation);
      },
       async (e) => {
        if (e.response?.status == 401) {
          showErrorToastNullable(toast, "Session Timeout! Please Login again");
 
          await sleep(5000);
          deleteStoredData();
          navigate("/");
        }
        showErrorToastNullable(toast, e.response?.data?.Message);
      });
  };

  function handleCertificateSelect(event) {
    setCertificates([...event.target.files]);
  }
  function handleTestCertificateSelect(event) {
    setTestCertificates([...event.target.files]);
  }

  function handleRemoveCertificate(index) {
    setCertificates(prevCerts => prevCerts.filter((cert, i) => i != index));
    document.getElementById("cert-upload").value = "";
  }
  function handleRemoveTestCertificate(index) {
    setTestCertificates(prevCerts => prevCerts.filter((cert, i) => i != index));
    document.getElementById("test-cert-upload").value = "";
  }
  
  async function uploadCertificate(type) {
    const formData = new FormData();
    let certificateType = "";
    if (type == 1) {
      certificates.forEach((cert, i) => {
        formData.append(cert.name, cert);
      })
      certificateType = "PfasCertificate";
    } else {
      testCertificates.forEach((cert, i) => {
        formData.append(cert.name, cert);
      })
      certificateType ="PfasTestCertificate";
    }

    await axios
    .post(
      `${Config.apiBaseAddress}/api/supplier/pfascertificate/${singleSelected?.id}/${certificateType}?idType=${idType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        getCertificates();
        if (type == 1) {
          setCertificates([]);
        } else {
          setTestCertificates([]);
        }
      })
  }

  return (
    <>
                <Toast ref={toast} />
              <Notifications notifications={notifications} onNotificationClick={onSubmit} />                {/* {isRunning &&<Toast ref={toastBC} />} */}
      <div className="card flex justify-content-center">
        <Card style={{ backgroundColor: "#f8f9fa" }}>
          {isSupplier(Role) && (<h1 style={{ color: "#003e7e" }}>Welcome</h1>)}
          
          {isInternalEnricher(Role) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}>Welcome to Bunzl Internal Enricher Portal</h1>
          </div>
          )}

          {isExternalEnricher(Role) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}> Welcome to Bunzl External Enricher Portal</h1>
          <div></div>
          </div>
          
          )}

          <h3 style={{ color: "#003e7e", paddingTop: "20px" }}>
            Here you'll find important information about navigating the portal
            and enriching your data.
          </h3>
          <h3 style={{ color: "#003e7e", paddingTop: "15px" }}>
            We'll need your assistance in updating product data and submitting
            that through the portal.
          </h3>
          <h3 style={{ color: "#003e7e" }}>
            Your progress will be tracked on this dashboard.
          </h3>
          
          <ItemStatuses supplierIds={supplierIds} manufacturerIds={manufacturers} isManufacturer={isManufacturer}/>

          <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab
              header={
                <span>
                  <h5 style={{marginTop:'10px'}}>
                    PFAS Survey{" "}                   
                  
                    { isPFASSurveyComplete ? <span className="text-success fw-bold">Completed</span> : <span className="required">* Required </span> }
                  </h5>{" "}
                </span>
              }
            >
              <div>
                <h5>
                  Suppliers, we must collect information about PFAS to ensure
                  that items containing intentionally added PFAS are not sold in
                  states with regulatory bans on PFAS. Your answers below will
                  drive the availability of your products in specific markets if
                  your products contain PFAS.
                </h5>
                <h5>
                  You will be able to skip this screen twice, but completion of
                  this screen will be required prior to entering item level data
                  on your third login. If you are completing data for more than
                  one company, please upload all relevant certificates.
                </h5>
                <div className="card flex justify-content-center mt-3">
                  <Card style={{ backgroundColor: "#f8f9fa" }}>
                    {!(suppliers?.length > 0) && manufacturers?.length > 0 ? 
                    <div>
                      <Dropdown
                        value={singleSelectedManufacturer}
                        onChange={onManufacturerChange}
                        options={manufacturers}
                        optionLabel="name"
                        placeholder="Select a Manufacturer"
                        className="w-full md:w-20rem"
                        showClear
                      />
                      <span className="required">* Required</span>
                    </div>
                    : <div>
                      <Dropdown
                        value={singleSelectedSuppliers}
                        onChange={onSupplierChange}
                        options={suppliers}
                        optionLabel="name"
                        placeholder="Select a Supplier"
                        className="w-full md:w-20rem"
                        showClear
                      />
                      <span className="required">* Required</span>
                    </div>}
                    <div style={{ fontSize: "large" }}>
                      1. Do you intentionally add PFAS in the manufacturing of
                      any of your products?
                    </div>
                    <div
                      className="flex flex-wrap gap-3"
                      style={{ marginTop: "31px", justifyContent: "left" }}
                    >
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="yesToAllRadio"
                          name="pfasOption"
                          value={1}
                          onChange={(e) => setIsPFASEnabled(e.value)}
                          checked={isPFASEnabled === 1}
                        />
                        <label htmlFor="yesRadio" className="ml-2">
                          Yes to All products
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="yesToSomeRadio"
                          name="pfasOption"
                          value={2}
                          onChange={(e) => setIsPFASEnabled(e.value)}
                          checked={isPFASEnabled === 2}
                        />
                        <label htmlFor="yesRadio" className="ml-2">
                          Yes to Some products
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="noRadio"
                          name="pfasOption"
                          value={0}
                          onChange={(e) => setIsPFASEnabled(e.value)}
                          checked={isPFASEnabled === 0}
                        />
                        <label htmlFor="noRadio" className="ml-2">
                          No to All products
                        </label>
                      </div>                    
                      
                    </div>
                    <div style={{ fontSize: "large" }} className="d-inline mb-3">
                    For all products without intentionally added PFAS, Bunzl requires you to certify this. 
                    You can sign and return Bunzl’s standard certification, available at this <Link onClick={certificatePDF}>
                        link
                      </Link>  or you
                        can provide alternate certification if your company has
                        a standard form.{" "}
                      </div>
                    <div style={{ fontSize: "large" }} className="mb-3">
                    If you select "No to ALL products", all your products will be automatically marked as "PFAS: No."
                    </div>
                    <div style={{ fontSize: "large" }} className="mb-3">
                    If you select "Yes to SOME products", you will need to specify the PFAS status for each individual product.                    </div>
                    <div style={{ fontSize: "large" }} className="mb-3">
                    If you select "Yes to ALL products", all your products will be automatically marked as "PFAS: Yes."                    </div>
                    {(isPFASEnabled === 0 || isPFASEnabled === 2) &&   
                    <>
                      <label htmlFor="cert-upload" id="cert-upload-label">
                        Choose File
                        <input id="cert-upload" type="file" name="certificates" onChange={handleCertificateSelect} hidden/>
                      </label>
                      <table>
                        <tbody>
                        {certificates.map((cert, i) => (
                          <tr className="filebox">
                            <td><h5>{cert.name}</h5></td>
                            <td><span className="close-upload" onClick={handleRemoveCertificate.bind(this, i)}>&#215;</span></td>
                            <td><a href={URL.createObjectURL(cert)} target="_blank">View</a></td>
                          </tr>))}
                          </tbody>
                      </table>
                    </>}
                    <div className="uploaded-certs">
                      {certificatesUploaded.map((cert) => (
                        <Link onClick={viewCertificate.bind(this,cert)} className="cert-link">                            
                            {cert.FileName}
                        </Link>
                      ))}
                    </div>
                    <hr
                      style={{ marginLeft: "1rem", marginRight: "1rem" }}
                    ></hr>
                   
                    <div style={{ fontSize: "large" }}>
                      2. Do you test any of your products for the presence of
                      PFAS? If yes, please add copies of your test results for all relevant items.
                    </div>
                    <div
                      className="flex flex-wrap gap-3 mb-2"
                      style={{ marginTop: "31px", justifyContent: "left" }}
                    >
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="testRadio"
                          name="pfasOption"
                          value={true}
                          onChange={(e) => setIsPFASTestEnabled(e.value)}
                          checked={isPFASTestEnabled === true}
                        />
                        <label htmlFor="testRadio" className="ml-2">
                          Yes
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="testNoRadio"
                          name="pfasOption"
                          value={false}
                          onChange={(e) => setIsPFASTestEnabled(e.value)}
                          checked={isPFASTestEnabled === false}
                        />
                        <label htmlFor="testNoRadio" className="ml-2">
                          No
                        </label>
                      </div>{" "}
                    </div>
                    {isPFASTestEnabled === true && 
                      <>
                      <label htmlFor="test-cert-upload" id="test-cert-upload-label">
                        Choose File
                        <input id="test-cert-upload" type="file" name="testCertificates" onChange={handleTestCertificateSelect} hidden/>
                      </label>
                      <table>
                        <tbody>
                        {testCertificates.map((cert, i) => (
                          <tr className="filebox">
                            <td><h5>{cert.name}</h5></td>
                            <td><span className="close-upload" onClick={handleRemoveTestCertificate.bind(this, i)}>&#215;</span></td>
                            <td><a href={URL.createObjectURL(cert)} target="_blank">View</a></td>
                          </tr>))}
                          </tbody>
                      </table>
                    </>}
                    <div className="uploaded-certs">
                      {testCertificatesUploaded.map((cert) => (
                        <Link onClick={viewTestCertificate.bind(this,cert)} className="cert-link">                            
                          {cert.FileName}
                        </Link> 
                      ))}
                    </div> 
                    <div className="mt-2 mb-2">
                    <Button
                      style={{ backgroundColor: "#003e7e", marginRight: "10px",marginTop:'10px' }}
                      label={"Skip for now" + (showSkipsRemaining ? ` (remaining ${MAX_SKIPS - skipCount})` : "")}
                      onClick={handleSkip}
                      disabled = {disabledSkip || (MAX_SKIPS - skipCount === 0)}
                      />
                    <Button
                      label="Submit"
                      style={{ backgroundColor: "#003e7e" }}
                      icon="pi pi-check"
                      onClick={handleSave}
                      disabled={
                        (!isManufacturer && !singleSelectedSuppliers) ||
                        (isManufacturer && !singleSelectedManufacturer) ||
                        isPFASEnabled == null ||
                        isPFASTestEnabled == null ||
                        (!certificates?.length && !certificatesUploaded?.length && (isPFASEnabled === 0 || isPFASEnabled === 2) ) ||
                        (!testCertificates?.length && !testCertificatesUploaded?.length && isPFASTestEnabled === true) 
                      }                      
                    />
                    </div>    
                    <hr style={{ marginLeft: "1rem", marginRight: "1rem" }}></hr>

                  </Card>
                </div>
              </div>
            </AccordionTab>
          </Accordion> 
                   
          <h3 className="mb-2 mt-2 text-danger">
            Please select the "Item List" from the left-hand side navigation and review the material data for any items that were unresolved from the last outreach or that are newly of interest to Bunzl.
            Please wait until you either see the item list or receive a confirmation that you have no new items to review.
          </h3> 

          <h5 style={{ color: "#003e7e", paddingTop: "30px" }}>
            Guides and Links
          </h5>
          <Accordion>
           <AccordionTab header="Getting Started">
              <div style={{ marginLeft: "30px" }}>
                <ol>
                  <li>
                    Please click the Item List page and fill in all required
                    fields for each item.
                  </li>
                  <li>
                    
                    Please check and correct or provide missing information
                    about each item. You may save your work for any item in
                    progress by clicking the 'Save' button at any time.
                  </li>
                  <li>
                    Once you have checked and completed all mandatory fields for
                    an item you may submit for our review. Our team will let you
                    know if we have any suggested corrections to your item
                    master data.
                  </li>
                  <li>
                    Need assistance? Contact our help desk for support. Our
                    dedicated support team is here to help you with any
                    questions or issues you may have. Please feel free to reach
                    out to us, and we'll respond to your inquiry as soon as
                    possible.
                    <b> Email: </b>{" "}
                    <a href="mailto:supplierportal@bunzlusa.com">
                      {" "}
                      supplierportal@bunzlusa.com{" "}
                    </a>
                  </li>
                  <li>
                    To avoid scrolling left to right kindly refer these
                    instructions :
                    <ul>
                      <li>
                        To zoom in, press CTRL and + (plus sign) together & to
                        zoom out, press CTRL and - (minus sign) together.{" "}
                      </li>
                      <li>
                        To modify the zoom level using the mouse and keyboard,
                        press and hold CTRL, then use the scroll wheel on your
                        mouse to zoom in and out.
                      </li>
                      <li>
                        Alternatively, you can use Chrome menus to zoom in and
                        out :
                        <ul>
                          <li>
                            Click or tap on the "Customize and control Google
                            Chrome" button located in the top-right corner of
                            the window.
                          </li>
                          <li>Go to Zoom</li>
                          <li>
                            Press the (-) button to zoom out and the (+) button
                            to zoom in.
                          </li>
                          <li>
                            The current zoom level is shown between the two
                            buttons.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </AccordionTab>
             <AccordionTab header="Frequently Asked Questions">
              <Link onClick={helpFAQPDF}>Frequently Asked Questions</Link>
            </AccordionTab>
            <AccordionTab header="Attribute Definitions, Guidelines and Examples">
              <Link onClick={helpAttributePDF}>
                Attribute Definitions, Guidelines and Examples
              </Link>
            </AccordionTab>
            <AccordionTab header="Standard Purchase Order Terms and Conditions">
              <Link onClick={TNCPDF}>
                Standard Purchase Order Terms and Conditions
              </Link>
            </AccordionTab> 
          </Accordion>
        </Card>
      </div>
    </>
  );
}
 
export default Dashboard;
 
