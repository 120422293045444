import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import Storage from "../utils/storage";
import axios from "axios";
import Config from "./../Config";
import { Toast } from "primereact/toast";
import logo from "./../assets/images/logo.png";
import {  
    getAccessToken ,
    deleteStoredData
  } from "../utils/http";
import {
    useNavigate,
    Navigate,
  } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { handleSubmits } from "../services/UserServices";
import { passwordValidator, PASSWORD_REQUIREMENTS } from "../utils/userValidator";
import { showErrorToast, showSuccessToast } from "../services/ToastService";

function ResetPassword() {

  const toast = useRef(null);
  const UserId = Storage.get("user");
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newValid, setValidNew] = useState(false);
  const [confirmValid, setValidConfirm] = useState(false);
  const [validationNewErrors, setValidationNewErrors] = useState("");
  const [validationConfirmErrors, setValidationConfirmErrors] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const location = useLocation();
  const token = getAccessToken();

  const searchParams = new URLSearchParams(location.search);
 const tokenParam = searchParams.get('token');
 const UserName = searchParams.get('username');
 

  const submitForm = (e) => {
    e.preventDefault();
    // setIsSubmitting(true);

    let payload = {
      Email: UserName,
      Id: "",
      UserName: UserName, 
      Token: tokenParam,
      Password:newPassword
    };
    // axios
    //   .post(`${Config.apiBaseAddress}/api/Forgotpassword`, payload, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
    //       "Access-control-allow-origin": "*",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
      handleSubmits(payload,token,(res) => {
        setNewPassword("");
        setConfirmPassword("");
        showSuccessToast(toast, "Password Changed successfully! Please Login");
        navigate("/");
      },
      (error) => {
        setNewPassword("");
        setConfirmPassword("");
        showErrorToast(toast, error.response?.data);

        if (e.response?.data?.errors != undefined) {
        }
      });
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    let newValue = e.target.value;
    if (newValue.length < 1) {
      setValidNew(false);
      setValidationNewErrors("Please enter new password.");
    } else if (!passwordValidator(newValue)) {
      setValidNew(false);
      setValidationNewErrors(PASSWORD_REQUIREMENTS);
    }else {
      setValidNew(true);
      setValidationNewErrors("");
    }
    validateConfirmPassword(confirmPassword, e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value, newPassword);
  };

  const validateConfirmPassword = (confirmPass, pass) =>
    {
      if (confirmPass !== pass) 
        {
          setValidConfirm(false);
          setValidationConfirmErrors("New password and Confirm password should be same.");
        } else 
        {
          setValidConfirm(true);
          setValidationConfirmErrors("");
        } 
    }
  

  //to render html
  return (
    <>
      <Toast ref={toast} />
      <div className="login-wraper">
      <div className="login-container">
      <div className="login-logo">
            <img src={logo} alt="Bunzl" className="logo-image"/>
          </div>
          <div style={{marginLeft:80}}>
      <form onSubmit={submitForm}>
        <div className="heading">
          <h1
            style={{
              marginLeft: "10px",
              marginBottom: "10px",
              paddingTop: "5px",
            }}
          >
            Reset Password
          </h1>
        </div>
        <div
          className="row profile-frm"
          style={{
            paddingLeft: "13px",
            paddingTop: "17px",
            fontWeight: "bold",
          }}
        >
           <div className="col-md-3">Email</div>
          <div className="col-md-9">
            <input type="text" value={UserName} disabled />
          </div>

          <div className="col-md-3">New Password</div>
          <div className="col-md-9">
            <input
              type="password"
              id="NewPassword"
              name="NewPassword"
              onChange={(e) => handleNewPassword(e)}
              value={newPassword}
            />
            <div>
              {!newValid && (
                <small className="p-error">{validationNewErrors}</small>
              )}
            </div>
          </div>
          <div className="col-md-3">Confirm Password</div>
          <div className="col-md-9">
            <input
              type="password"
              name="ConfirmPassword"
              onChange={(e) => handleConfirmPassword(e)}
              value={confirmPassword}
            />
            <div>
              {!confirmValid && (
                <small className="p-error">{validationConfirmErrors}</small>
              )}
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-9" style={{ paddingTop: "31px" }}>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: "#003e7e" }}
              disabled={!confirmPassword||!newPassword||!newValid||!confirmValid}
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
    </div>
    </div>
    </>
  );
}

export default ResetPassword;
