import React, { Component } from 'react'
//page not found
export default class PageNotFound extends Component {
    render() {
        return (
            <div className="text-center">
                <div style={{fontSize:90}}>404</div>
                <h1>Oops!</h1>
                <h4>This page doesn't exist or is unavailable.</h4>
                <h4>Kindly Logout and Login again.</h4>
                <h4>If problem persists please contact support team.</h4>
                {/* <a href="/dashboard" class="btn btn-primary">Go Back to Home</a> */}
            </div>
        )
    }
}
