import axios from "axios";
import { API_HEADER, API_HEADER_MULTIPART,USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, setAccessToken } from "../utils/http";
import { httpGET , httpPOST, httpPUT} from "../utils/http";

export const fetchNotificationService = async (userId, token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/notifications/${userId}`, { headers });
    callback(res);
} 
catch (error) {
  errorCallback(error);
}
};

export const handleNotificationClickService = async (id, token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
    "Content-Type": "application/json",
  };
  try {
    const res = await httpPOST(`/notifications/${id}`, {}, headers);
    callback(res);
} 
catch (error) {
  errorCallback(error);
}
};

