import { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import Storage from "../utils/storage";
import { isViewer } from "../utils/roleValidator";

const Role = Storage.get("Role");
const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
}) => {
  const [text, setText] = useState(initialText);
  const isTextareaDisabled = text.length === 0;
  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text);
    setText("");
  };
  return (
    <form onSubmit={onSubmit}>
      <InputTextarea value={text} onChange={(e) => setText(e.target.value)}/>
        {/* <button className="comment-form-button" disabled={isTextareaDisabled} style={{ backgroundColor: "#003e7e" }}> */}
        { isViewer(Role) ? (
              <>
                <button className="comment-form-button" disabled style={{ backgroundColor: "#003e7e" }}>{submitLabel}</button>
              </>
              ):
              <>
                <button className="comment-form-button" disabled={isTextareaDisabled} style={{ backgroundColor: "#003e7e" }}>{submitLabel}</button>
              </>
              }

      {hasCancelButton && (
        <button type="button" className="comment-form-button comment-form-cancel-button" onClick={handleCancel}  style={{ backgroundColor: "#003e7e" }}>
          Cancel
        </button>
      )}
    </form>
  );
};

export default CommentForm;