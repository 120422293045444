export const Status = {
  PENDING: "Pending",
  APPROVED: "Approved",
  IN_REVIEW: "In Review",
  IN_PROGRESS: "In Progress",
  INTERNALLY_ENRICHED: "Internally Enriched",
  EXTERNALLY_ENRICHED: "Externally Enriched",
  EXTERNALLY_REVIEWED: "ExternallyReviewed",
  INTERNALLY_REVIEWED: "Internally Reviewed",
  INTERNALLY_INPROGRESS: "In Progress Internal Enricher",
  EXTERNALLY_INPROGRESS: "In Progress External Enricher",
  PENDING_INTERNAL: "Pending Internal Enricher",
  PENDING_EXTERNAL: "Pending External Enricher",
  INTERNALREVIEW_ESG: "Review In ESG Internal Enricher",
  EXTERNALREVIEW_ESG: "Review In ESG External Enricher",
  ERRORHANDLING_INREVIEW: "Review In Esg",
  INTERNALLY_APPROVED: "Approved Internal Enricher",
  EXTERNALLY_APPROVED: "Approved External Enricher",
};

export function isStatusPending(status) {
  return status?.Status == Status.PENDING;
}
export function isStatusApproved(status) {
  return status?.Status == Status.APPROVED;
}
export function isStatusInReview(status) {
  return status?.Status == Status.IN_REVIEW;
}
export function isStatusInProgress(status) {
  return status?.Status == Status.IN_PROGRESS;
}
export function isStatusInternallyEnriched(status) {
  return status?.Status == Status.INTERNALLY_ENRICHED;
}
export function isStatusExternallyEnriched(status) {
  return status?.Status == Status.EXTERNALLY_ENRICHED;
}
export function isStatusExternallyReviewed(status){
  return status?.Status == Status.EXTERNALLY_REVIEWED;
}
export function isStatusInternallyReviewed(status){
  return status?.Status == Status.INTERNALLY_REVIEWED;
}
export function isStatusPendingInternal(status){
  return status?.Status == Status.PENDING_INTERNAL;
}
export function isStatusPendingExternal(status){
  return status?.Status == Status.PENDING_EXTERNAL;
}
export function isStatusInternallyProgress(status){
  return status?.Status == Status.INTERNALLY_INPROGRESS;
}
export function isStatusExternallyProgress(status){
  return status?.Status == Status.EXTERNALLY_INPROGRESS;
}
export function isStatusInternalEsgReview(status){
  return status?.Status == Status.INTERNALREVIEW_ESG;
}
export function isStatusExternalEsgReview(status){
  return status?.Status == Status.EXTERNALREVIEW_ESG;
}
export function isStatusInReviewEsg(status){
  return status?.Status == Status.ERRORHANDLING_INREVIEW;
}
export function isStatusActiveUsers(status) {
  return status?.Status == Status.ACTIVE_USERS;
}
export function isStatusSuppliers(status) {
  return status?.Status == Status.TOTAL_SUPPLIERS;
}
export function isStatusSupplierUsers(status) {
  return status?.Status == Status.TOTAL_SUPPLIERUSERS;
}
export function isStatusInternalApproved(status){
  return status?.Status == Status.INTERNALLY_APPROVED;
}
export function isStatusExternalApproved(status){
  return status?.Status == Status.EXTERNALLY_APPROVED;
}
