import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "./../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DO_LOGIN, LOGIN_SUCCESS } from "../store/actions";
import { doLogin } from "../store/actions/action";
import { Button } from "primereact/button";

function Login() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.User?.UserId);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const isLoading = useSelector((store) => store.user.loading);

  const loginAction = (e) => {
    setValidationErrors({});
    e.preventDefault();

    try {
      const data = { email, password };
      dispatch(doLogin(data));
    } catch (e) {}
  };

  return (
    <div>
      {/* <div className="row justify-content-md-center mt-5"> */}
      <div className="login-wraper">
        <div className="login-container">
          <div className="login-logo">
            <img src={logo} alt="Bunzl" className="logo-image"/>
          </div>
          <div className="row justify-content-md-center mt-5">
            <div className="col-10">
              <div className="card" style={{ top: "-86px" }}>
                <div className="card-body">
                  <h5 className="card-title mb-4">Sign In</h5>
                  <form
                    onSubmit={(e) => {
                      loginAction(e);
                    }}
                  >
                    {Object.keys(validationErrors).length !== 0 && (
                      <p className="text-center ">
                        <small className="text-danger">
                          Incorrect Email or Password
                        </small>
                      </p>
                    )}

                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                    {/* <div className="nav-link"><a href="/forgotpassword"><i className="fa fa-cog"></i>Forgot Password</a></div> */}
                    <Link to="/forgotpassword">Forgot Password</Link>
                    <div className="d-grid gap-2">
                      <Button
                        loading={isLoading}
                        type="submit"
                        label="Login"
                        style={{ backgroundColor: "#003e7e" }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
