import { isSupplier } from "../utils/roleValidator";


export const groupedMaterialType = [
  {
    label: "Chemical",
    items: [{ label: "Chemical", value: 1 }],
  },
  {
    label: "Corrugated_Fiber",
    items: [
      { label: "Corrugated_Fiber", value: 1 },
      { label: "Bleached & Uncoated", value: 2 },
      { label: "Unbleached & Coated", value: 3 },
      { label: "Unbleached & Uncoated", value: 4 },
    ],
  },
];

export const customPrinted = [
  { name: "Yes", id: 1 },
  { name: "No", id: 0 },
];

export const productUseByConsumer = [
  { name: "Unknown", id: 2 },
  { name: "Yes", id: 1 },
  { name: "No", id: 0 },
];

export const IncludesPFAS = (role) => [
  { name: "Unknown", id: 2, disabled: isSupplier(role) },
  { name: "Yes", id: 1 },
  { name: "No", id: 0 },
];

export const productTypeLid = [
  { name: "Cup", id: 0 },
  { name: "Container", id: 1 },
  { name: "Lid", id: 2 },
  { name: "Film", id: 3 },
  { name: "Cup/Lid Combo", id: 4 },
  { name: "Container/Lid Combo", id: 5 },
];
export const plasticFilmPrePost = [
  { name: "Before Embossing", id: 0 },
  { name: "After Embossing", id: 1 },
];

export const reusable = [
  { name: "Reusable", id: 0 },
  { name: "Refillable", id: 1 },
  { name: "Reusable and Refillable", id: 2 },
  { name: "Not Reusable or Refillable", id: 3 },
];

export const countries = [
  { name: "Australia", code: "AU" },
  { name: "Brazil", code: "BR" },
  { name: "China", code: "CN" },
  { name: "Egypt", code: "EG" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "India", code: "IN" },
  { name: "Japan", code: "JP" },
  { name: "Spain", code: "ES" },
  { name: "United States", code: "US" },
];

export const hfmEnricherCodes = [
  { label: "7810 - Alternative materials", value: "7810" },
  { label: "7820 - Important purpose", value: "7820"},
  { label: "7830 - Consumables likely to transition", value: "7830"},
  { label: "7840 - Consumables facing regulation", value:"7840"},
  { label: "8888 - Potentially packaging", value:"8888"},
  { label: "9999 - Non Packaging", value: "9999"}
];