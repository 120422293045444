import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "../actions";
import jwt_decode from "jwt-decode";
import {
  deleteStoredData,
  httpPOST,
  setAccessToken,
  setRefreshToken,
} from "../../utils/http";
import { API_HEADER, LOGIN_API } from "../../constants/ApiConstants";
import { decryptData, encryptData } from "../../utils/cryptoHelper";
import { Link, useNavigate, useRef } from "react-router-dom";
// import { USERNAME_PREFIX } from '../../constants/AppConstants'
import Storage from "../../utils/storage";
import { Navigate } from "react-router-dom";
import register from "../../services/UserService";

export function* login(data) {
  // console.log("datacheck", data);
  const body = {
    // email: data.data.userName + USERNAME_PREFIX,

    UserEmail: data.data.email,
    Password: data.data.password,
  };

  try {
    // yield put({ type: actions.LOGIN_FAILED, data: false });
    // console.log("Api header", API_HEADER);
    const response = yield httpPOST(LOGIN_API, body, API_HEADER);
    // console.log("response :-", response);
    if (response.status === 200) {
      const Role = jwt_decode(response?.data?.Token);
      console.log("Role",Role)
      const Rolerole= Role?.role;
      Storage.set("Role", Role?.role);
      const userInfo = encryptData(response?.data?.user);
      const userInfoToken = response?.data?.User?.UserId;
      const suppliers = response?.data?.User?.Suppliers;
      const manufacturers = response?.data?.User?.Manufacturers.Manufracturers;
      // console.log('userid',userInfoToken);
      Storage.set("suppliers", suppliers);   
      Storage.set("user", userInfoToken);
      Storage.set("manufacturers", manufacturers);
      
      const surveySkipped = {};
      const surveySubmitted = {}
      suppliers?.Suppliers.forEach(supplier => {
        surveySkipped[supplier.id] = false;
        surveySubmitted[supplier.id] = false;
      });
      manufacturers?.forEach(manufacturer => {
        surveySkipped[manufacturer.id] = false;
        surveySubmitted[manufacturer.id] = false;
      })
      Storage.set("survey", [surveySkipped, surveySubmitted]);

      setAccessToken(response.data.Token);
      setRefreshToken(response.data.refreshToken);

      Storage.set("secondaryCategories", {});
      Storage.set("subCategories", {});
      Storage.set("materialSubTypes", {});

      yield put({
        type: actions.LOGIN_SUCCESS,
        data: response.data.user,
      });
    } else {
      yield put({ type: actions.LOGIN_FAILED, data: true });
    }
  } catch (e) {
    // console.error("catching err :-", e);
    //  const ResponseMessage=e.response?.statusText;
    //  Storage.set('ResponseMessage',ResponseMessage);
    alert("Incorrect email or password");

    yield put({ type: actions.LOGIN_FAILED, data: true });
  }
}

export function logout() {
  deleteStoredData();

  put({
    type: actions.LOGOUT,
  });
}

export function* addUser(action) {
  const users = yield call(register, action);
  // console.log("register", users);
  if (users.errors) {
    // eslint-disable-next-line no-undef
    yield put({ type: actions.ADD_USER_FAILED, message: message });
  } else {
    yield put({ type: actions.ADD_USER_SUCCESS, users: users });
  }
}

export function* watchUsersCall() {
  yield takeLatest(actions.DO_ADD_USER, addUser);
  yield takeLatest(actions.DO_LOGIN, login);
  yield takeLatest(actions.LOGOUT, logout);
}
