import React, { useState, useEffect, useRef } from "react";
import Storage from "../utils/storage";
import axios from "axios";
import Config from "./../Config";
import { Toast } from "primereact/toast";
import {
    useNavigate,
    Navigate,
  } from "react-router-dom";
import { passwordValidator, PASSWORD_REQUIREMENTS } from "../utils/userValidator";
import { submitForms } from "../services/UserServices";
import { showErrorToast, showSuccessToast } from "../services/ToastService";

function Profile() {
  const toast = useRef(null);
  const UserId = Storage.get("user");
  const navigate = useNavigate();
  const [UserName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldValid, setValidOld] = useState(false);
  const [newValid, setValidNew] = useState(false);
  const [confirmValid, setValidConfirm] = useState(false);
  const [validationOldErrors, setValidationOldErrors] = useState("");
  const [validationNewErrors, setValidationNewErrors] = useState("");
  const [validationConfirmErrors, setValidationConfirmErrors] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = Storage.get("accessToken");

  const submitForm = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let payload = {
      userName: UserId,

      oldPassword: oldPassword,

      newPassword: newPassword,
    };
      submitForms(payload,token,(res) => {
        setIsSubmitting(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        localStorage.setItem("token", res.data);
        showSuccessToast(toast, "Password Changed successfully!Please Login");
      },
      (error) => {
        showErrorToast(toast, error.response?.data?.Message);

        setIsSubmitting(false);
        if (e.response?.data?.errors != undefined) {
        }
      });
  };

  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
    let oldValue = e.target.value;
    if (oldValue.length < 1) {
      setValidOld(false);
      setValidationOldErrors("Please enter old password.");
    } else if (oldValue.length >= 1 && newPassword.length >= 1) {
      setValidOld(true);
      setValidationOldErrors("");
      if (oldValue === newPassword) {
        setValidOld(false);
        setValidationOldErrors(
          "Old password and new password should not be same."
        );
      }
    } else {
      setValidOld(true);
      setValidationOldErrors("");
    }
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    let newValue = e.target.value;
    if (newValue.length < 1) {
      setValidNew(false);
      setValidationNewErrors("Please enter new password.");
    } else if (!passwordValidator(newValue)) {
      setValidNew(false);
      setValidationNewErrors(PASSWORD_REQUIREMENTS);
    } else if (newValue.length >= 1 && oldPassword.length >= 1) {
      setValidationNewErrors("");
      setValidNew(true);
      if (newValue === oldPassword) {
        setValidNew(false);
        setValidationNewErrors(
          "New password and old password should not be same."
        );
      }
    } else {
      setValidationNewErrors("");
      setValidNew(true);
    }
    validateConfirmPassword(confirmPassword, e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value, newPassword);
  };

  const validateConfirmPassword = (confirmPass, pass) =>
  {
    if (confirmPass !== pass) 
      {
        setValidConfirm(false);
        setValidationConfirmErrors("New password and Confirm password should be same.");
      } else 
      {
        setValidConfirm(true);
        setValidationConfirmErrors("");
      } 
  }


  //to render html
  return (
    <>
      <Toast ref={toast} />
      <form onSubmit={submitForm}>
        <div className="heading">
          <h1
            style={{
              marginLeft: "10px",
              marginBottom: "10px",
              paddingTop: "5px",
            }}
          >
            Change Password
          </h1>
        </div>
        <div
          className="row profile-frm"
          style={{
            paddingLeft: "13px",
            paddingTop: "17px",
            fontWeight: "bold",
          }}
        >
          <div className="col-md-3">Email</div>
          <div className="col-md-9">
            <input type="text" value={UserId} disabled />
          </div>

          <div className="col-md-3">Old Password</div>
          <div className="col-md-9">
            <input
              type="password"
              id="OldPassword"
              name="OldPassword"
              onChange={(e) => handleOldPassword(e)}
              value={oldPassword}
            />
            <div>
              {!oldValid && (
                <small className="p-error">{validationOldErrors}</small>
              )}
            </div>
          </div>
          <div className="col-md-3">New Password</div>
          <div className="col-md-9">
            <input
              type="password"
              id="NewPassword"
              name="NewPassword"
              onChange={(e) => handleNewPassword(e)}
              value={newPassword}
            />
            <div>
              {!newValid && (
                <small className="p-error">{validationNewErrors}</small>
              )}
            </div>
          </div>
          <div className="col-md-3">Confirm Password</div>
          <div className="col-md-9">
            <input
              type="password"
              name="ConfirmPassword"
              onChange={(e) => handleConfirmPassword(e)}
              value={confirmPassword}
            />
            <div>
              {!confirmValid && (
                <small className="p-error">{validationConfirmErrors}</small>
              )}
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-9" style={{ paddingTop: "31px" }}>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: "#003e7e" }}
              disabled={!confirmPassword||!oldPassword||!newPassword||!oldValid||!newValid||!confirmValid}
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Profile;
