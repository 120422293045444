import axios from "axios";
import { USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, setAccessToken } from "../utils/http";
import { httpGET , httpPOST, httpPUT} from "../utils/http";

export const submitForms = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/ChangePassword', payload, headers);
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const handleSubmits = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/Forgotpassword', payload, headers);
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const registerUser = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/AddUser', payload, headers);
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };