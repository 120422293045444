export function showSuccessToast(toast, message, lifeSpan = 3000, summary = "Successful")
{
  toast.current.show({
    severity: "success",
    summary: summary,
    detail: message,
    life: lifeSpan,
  });
}

export function showWarningToast(toast, message, lifeSpan = 3000, summary = "Warning")
{
  toast.current.show({
    severity: "warn",
    summary: summary,
    detail: message,
    life: lifeSpan,
  });
}

export function showErrorToast(toast, message, lifeSpan = 3000, summary = "Error")
{
  toast.current.show({
    severity: "error",
    summary: summary,
    detail: message,
    life: lifeSpan,
  });
}

export function showErrorToastNullable(toast, message, lifeSpan = 3000, summary = "Error")
{
  toast?.current?.show({
    severity: "error",
    summary: summary,
    detail: message,
    life: lifeSpan,
  });
}