import * as actions from "../actions";
import { decryptData } from "../../utils/cryptoHelper";
// import Storage from '../../utils/storage'

// let uInfo = {}
// try {
//  uInfo = decryptData(Storage.get('user'))
// } catch (e) {
//  console.log('something went wrong')
// }

const initialState = {
  //  isLoggedIn: uInfo ? true : false,
  isLoggedIn: false,
  name: "",
  loading: false,
  //  ...uInfo
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.DO_LOGIN: {
      return { ...state, loading: true, loginFailed: false };
    }

    case actions.LOGIN_SUCCESS: {
      return { ...state, loading: false, isLoggedIn: true, ...action.data };
    }

    case actions.LOGOUT: {
      return { ...initialState };
    }

    case actions.LOGIN_FAILED: {
      return { ...state, loading: false, loginFailed: action.data };
    }

    default:
      return state;
  }
};

export default loginReducer;
