import axios from "axios";
import { USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, setAccessToken } from "../utils/http";
import { httpGET , httpPOST, httpPUT} from "../utils/http";

export const saveUsers = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/AddUser', payload, headers);
      callback(res.data);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const getUsers = async (allRecords, token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/GetUsers/${allRecords}`, { headers });
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const viewUsers = async (userName,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/GetUser/${userName}`, { headers });
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const editSaveUsers = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPUT('/UpdateUser', payload, headers);
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const changePassword = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/ChangeSupplierPassword', payload, headers);
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const groupSupplier = async (showAll, token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/supplierGroup?showAll=${showAll}`, { headers });
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const getSupplier = async (groupDropdownAdd,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/supplierGroup/${groupDropdownAdd}`, { headers });
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const getSuppliersList = async (showAll, token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/suppliers?showAll=${showAll}`, { headers });
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const getManufacturersList = async (showAll, token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/manufacturer/manufacturers?showAll=${showAll}`, { headers });
      callback(res);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const getNominatedByList = async (token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET('/nominators', { headers });
      console.log("res",res);
      callback(res);
      
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const saveSupplierGroupDetails = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/supplier/UpsertSupplierGroup', payload, headers);
      callback(res.data);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const saveSupplierDetails = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/supplier/UpsertSupplier', payload, headers);
      callback(res.data);
    } 
    catch (error) {
      errorCallback(error);
    }
  };

  export const saveManufacturerDetails = async (payload,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST('/manufacturer/UpsertManufacturer', payload, headers);
      callback(res.data);
    } 
    catch (error) {
      errorCallback(error);
    }
  };
