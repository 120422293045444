import React from "react";
import { Navigate, Routes, Redirect, Route } from "react-router-dom";
import Storage from "./storage";
import Proptypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";

const PrivateRoute = ({ component: component, ...rest }) => {
  const user = useSelector((store) => store.user.isLoggedIn);
  let profile = Storage.get("user");
  return profile ? component : <component {...rest} />;
};

const RedirectRoute = ({ component }) => {
  const Role = Storage.get("Role");

  const user = useSelector((store) => store.user.isLoggedIn);
  let profile = Storage.get("user");

  return profile ? <Navigate to="/dashboard" /> : component;
};

const PublicRoute = ({ component }) => {
  // console.log("componet :-", component);

  const user = useSelector((store) => store.user.isLoggedIn);
  let profile = Storage.get("user");

  return profile ? <Navigate to="/register" /> : component;
};

// const ProtectedRoute = ({ component :children, ...rest }) => {
//     console.log("checking :-", children, "r :- ", rest)
//     return (
//       <Routes
//         {...rest}
//         render={({ location }) =>
//         Storage.get('user') ? (
//             <component {...rest}/>
//           ) : (
//             <Route
//               to={{
//                 pathname: "/",
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     );
//     // return Storage.get('user') ? children :<Navigate to="/" />
//   };

PrivateRoute.propTypes = {
  component: Proptypes.element.isRequired,
};
RedirectRoute.propTypes = {
  component: Proptypes.element.isRequired,
};

PublicRoute.propTypes = {
  component: Proptypes.element,
};

//    ProtectedRoute.propTypes = {
//     component: Proptypes.element
//    }

export {
  PrivateRoute,
  RedirectRoute,
  PublicRoute,
  // ProtectedRoute
};
