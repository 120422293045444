import * as actions from '../actions/index'
export const doLogin = (data) => {
    return {
      type: actions.DO_LOGIN,
      data:data
    };
  };


  export const doAddUser = (data) => {
    return {
      type: actions.DO_ADD_USER,
      data:data
    };
  };


  