import axios from "axios";
import { API_HEADER, API_HEADER_MULTIPART,USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, setAccessToken } from "../utils/http";
import { httpGET , httpPOST, httpPUT} from "../utils/http";

export const getPFAData = async (userId, token, idType, callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/supplier/preferences/${userId}?idType=${idType}`,{ headers });
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const getNotificationPopup = async (userId, token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/notifications/${userId}`, { headers });
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const getCertificatesPDFs = async (userId, token, idType, callback, errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };

  try {
    const res = await httpGET(`/supplier/pfascertificate/${userId}?idType=${idType}`,{ headers });
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const handleSubmit = async (data, id, token, idType, callback, errorCallback = (error) => console.error(error)) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
    "Content-Type": "application/json",
  };

  // Construct the URL based on idType
  let url = `/supplier/preferences/${id}`;
  
  // Append the idType as a query parameter if it's a manufacturer
  if (idType === 'manufacturer') {
    url += `?idType=manufacturer`;
  }

  console.log(`Submitting preferences to URL: ${url} with ID: ${id}, idType: ${idType}`);

  try {
    const res = await httpPUT(url, data, headers); 
    callback(res);
  } catch (error) {
    console.error("Error during handleSubmit:", error); 
    errorCallback(error);
  }
};


export const handleSkips = async (data, id, token, idType, callback, errorCallback = (error) => console.error(error)) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
    "Content-Type": "application/json",
  };

  // Construct the URL based on idType
  let url = `/supplier/preferences/${id}`;

  // Append the idType as a query parameter if it's a manufacturer
  if (idType === 'manufacturer') {
    url += `?idType=manufacturer`;
  }
  
  try {
    const res = await httpPUT(url, data, headers);
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};


export const handleOkSubmit = async (id, token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
    "Content-Type": "application/json",
  };

  try {
    const res = await httpPOST(`/notifications/${id}`, {}, headers);
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const uploadCertificates = async (id, certificateType, formData, token, idType, callback, errorCallback = (error) => console.error(error)) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
    "Content-Type": "multipart/form-data",
  };

  // Construct the URL based on idType
  let url = `/supplier/pfascertificate/${id}/${certificateType}`;
  
  // Append the idType as a query parameter if it's a manufacturer
  if (idType === 'manufacturer') {
    url += `?idType=manufacturer`;
  }

  console.log(`Uploading certificate to URL: ${url} with ID: ${id}, idType: ${idType}, certificateType: ${certificateType}`);

  try {
    const res = await httpPOST(url, formData, headers); 
    callback(res);
  } catch (error) {
    console.error("Error during uploadCertificates:", error); 
    errorCallback(error);
  }
};


export const fetchCertificatePDF = async (callback,errorCallback) => {
  try {
    const response = await httpGET('/gettemplate/PFAS_Vendor Certificate of Compliance.pdf', { responseType: "blob" });
    callback(response);
} 
catch (error) {
  errorCallback(error);
}
}

export const helpFAQPDFs = async (callback,errorCallback) => {
  try {
    const response = await httpGET('/HelpFile', {
      responseType: "blob",
    });
    callback(response);
} 
catch (error) {
  errorCallback(error);
}
}

export const helpAttributePDFs = async (callback,errorCallback) => {
  try {
    const response = await httpGET('/AttriubteHelpFile', {
      responseType: "blob",
    });
    callback(response);
} 
catch (error) {
  errorCallback(error);
}
}

export const TNCPDFs = async (callback,errorCallback) => {
  try {
    const response = await httpGET('/TermsAndConditions', {
      responseType: "blob",
    });
    callback(response);
} 
catch (error) {
  errorCallback(error);
}
}

export const viewCertificatePDFs = async (fileLocation, userId, token,callback,errorCallback) => {
  const formDataObject = {
    certificateFileType: "PfasCertificate",
    fileLocation: fileLocation
  };
  viewCertificatePDF(formDataObject, userId, token, callback, errorCallback);
};

export const viewTestCertificatePDFs = async (fileLocation, userId, token,callback,errorCallback) => {
  const formDataObject = {
    certificateFileType: "PfasTestCertificate",
    fileLocation: fileLocation
  };
  viewCertificatePDF(formDataObject, userId, token, callback, errorCallback);
};

const viewCertificatePDF = async (formDataObject, userId, token,callback,errorCallback) => {
  const formDataString = JSON.stringify(formDataObject);

  const info = {
    headers: {
      ...API_HEADER,
      ...API_HEADER_MULTIPART,
      Authorization: `Bearer ${token || getAccessToken()}`,
    },
    responseType: 'blob'
  };

  try {
    const response = await axios.post(
      `${USERS_BASE_API}/supplier/pfascertificate/${userId}`, 
      formDataString, 
      info);
  
    callback(response);
  } 
  catch (error) {
    errorCallback(error);
  }
};
