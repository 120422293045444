import Config from "./../Config";
 
export const API_HEADER = {
  "Access-control-allow-origin": "*",
  "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
};
 
export const API_HEADER_MULTIPART = { "Content-Type": "multipart/form-data" };
 
export const API_HEADER_JSON = { "Content-Type": "application/json" }

export const USERS_BASE_API = `${Config.apiBaseAddress}/api`;
 
export const LOGIN_API = "/Token";
 
export const DASHBOARD_ROUTE = "/";
 
export const MAX_SKIPS = 2;