import { API_HEADER, API_HEADER_JSON } from "../constants/ApiConstants";
import { getAccessToken } from "../utils/http";
import { httpPOST } from "../utils/http";

export const getCertificates = async (body, token,callback,errorCallback) => {
  const headers = {
    ...API_HEADER,
    ...API_HEADER_JSON,
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpPOST('/supplier/GetPFASCertificateDetails', body, headers );
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};
