import axios from "axios";
import { API_HEADER, USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, setAccessToken } from "../utils/http";
import { httpGET , httpPOST, httpPUT} from "../utils/http";
import Config from "../Config";

  export const saveProducts = async (emptyProduct,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPUT('/item/review', emptyProduct, headers);
      callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
  };

  export const saveProductsUpdate = async (emptyProduct,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPUT('/item/update', emptyProduct, headers);
      callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
  };

  export const viewDetails = async (itemId,token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpGET(`/GetItem/${itemId}`, { headers });
      callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
  };

  export const supplierItemList = async (body, token, callback, errorCallback) => {
    const headers = {
      ...API_HEADER,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getAccessToken()}`,
    };
    try {
      const res = await httpPOST("/items/paginatedsummary", body, headers);
      callback(res);
    } catch (error) {
      errorCallback(error);
    }      
  };

  export const DeactivateItem = async (body, token, callback, errorCallback) => {
    const headers = {
      ...API_HEADER,
      Authorization: `Bearer ${token || getAccessToken()}`,
    }
    try {
      const res = await httpPOST("/item/DisableItem", body, headers);
      callback(res);
    } catch (error) {
      errorCallback(error);
    }
  };
