import React, { useState, useEffect, useRef } from "react";
import Storage from "../utils/storage";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import axios from "axios";
import Config from "./../Config";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MAX_SKIPS } from "../constants/ApiConstants";
import { FileUpload } from "primereact/fileupload";
import ItemStatuses from "../components/ItemStatuses";
import Notifications from "../components/Notification";
import { getPFAData, handleSubmit, getCertificates,fetchCertificatePDF,
helpFAQPDFs,helpAttributePDFs,TNCPDFs, getNotificationPopup,
handleOkSubmit,handleSkips,viewCertificatePDFs,viewTestCertificatePDFs,
getCertificatesPDFs,uploadCertificates } from "../services/DashboardService";
import { isInternalEnricher, isExternalEnricher, isQuality, isInternalQuality, isExternalQuality, isESG } from "../utils/roleValidator";
import {
  deleteStoredData,
  httpPOST,
  getAccessToken,
  setRefreshToken,
} from "../utils/http";

function NewDashboard(props) {
  const token = getAccessToken();
  const [activeIndex, setActiveIndex] = useState(0); 
  const [isPFASEnabled, setIsPFASEnabled] = useState(null); // State to manage PFAS option
  const [isPFASTestEnabled, setIsPFASTestEnabled] = useState(null); // State to manage PFAS test option
  const [skipCount, setSkipCount] = useState(0);
  const supplierId = Storage.get("selectedSuppliers");
  const {selectedSuppliers: supplierIds } = props;
  const toast = useRef(null);
  const toastBC = useRef(null);
  const navigate = useNavigate();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const suppliersgroup = Storage.get("suppliers");
  const suppliergroupname = suppliersgroup?.GroupName;
  const suppliers = suppliersgroup?.Suppliers;
  const [singleSelectedSuppliers, setSingleSelectedSuppliers] = useState(suppliers?.length > 0 ? suppliers[0] : null);
  const [certificates, setCertificates] = useState([]);
  const [testCertificates, setTestCertificates] = useState([]);
  const[fileLocation,setFileLocation] = useState();
  const UserId = Storage.get("user");
  const [notificationId, setIsNotificationId] = useState('');
  const [notifications, setNotifications] = useState([]);
  const Role = Storage.get("Role");
  const onSupplierChange = (e) => {
    setSingleSelectedSuppliers(e.value);
  };
  if (singleSelectedSuppliers?.length !== 0) {
    Storage.set("singleSelectedSuppliers", singleSelectedSuppliers);
  } else {
    Storage.remove("singleSelectedSuppliers");
  }
 
  const notificationPopup = async () => {
    getNotificationPopup(UserId,token,(res) =>{
      if(res?.status === 200 && res?.data?.length > 0) {
        setNotifications(res.data);
      }},
     async(e) => {
      // Handle error
    })
  };
     
  
  return (
    <>
     <Toast ref={toast} />
      <div className="card flex justify-content-center">
        <Card style={{ backgroundColor: "#f8f9fa" }}>
          {isInternalEnricher(Role) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}>Welcome to Bunzl Internal Enricher Portal</h1>
          </div>
          
          )}

          {isExternalEnricher(Role) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}> Welcome to Bunzl External Enricher Portal</h1>
          <div></div>
          </div>
          
          )}

          {isExternalQuality(Role) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}> Welcome to External Quality Assurance Portal</h1>
          <div></div>
          </div>
          
          )}
          {isInternalQuality(Role) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}> Welcome to Internal Quality Assurance Portal</h1>
          <div></div>
          </div>
          
          )}

          {isESG(Role) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}> Welcome to Bunzl ESG Team Portal</h1>
          <div></div>
          </div>

          )}
          <ItemStatuses supplierIds={supplierIds}/>
        </Card>
      </div>
    </>
  );
}
 
export default NewDashboard;
 
