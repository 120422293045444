import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import {  
  getAccessToken ,
  deleteStoredData
} from "../utils/http";
import Config from "../Config";
import Storage from "../utils/storage";
import { isQuality, isViewer } from '../utils/roleValidator';
import ItemStatuses from './ItemStatuses';

const DashboardApprover = () => {
  const supplierId = Storage.get('selectedSuppliers');
  const token = getAccessToken();
  const userId = Storage.get("user");
  const Role = Storage.get("Role");
  const [totalSuppliersOnboarded, setTotalSuppliersOnboarded] = useState(0);
  const [totalUsersWithSupplier, setTotalUsersWithSupplier] = useState(0);
  const [totalActiveUsersWithSupplier, setTotalActiveUsersWithSupplier] = useState(0);
 
  return (
    <div className="card flex justify-content-center" style={{ paddingTop: '15px' }}>
      { isViewer(Role) ? (
        <>
          <h1 className="dashboardApprover__title"> Welcome to Bunzl Viewer Portal <br /><br /></h1>
        </>):
        (<>
          <h1 className="dashboardApprover__title"> Welcome to Bunzl Approver Portal <br /><br /></h1>
        </>
      )}
      { isQuality(Role) && (
        <h1 className="dashboardApprover__title"> Welcome to Bunzl Quality Assurance Portal <br /><br /></h1>
      )}
      <div>
        {/* First row of cards */}
        <div className='dashboardApprover__row1'>
          <Card
            title="Total Suppliers Onboarded"
            className='dashboardApprover__card'
          >
            <p className="m-0 normal-font-color dashboardApprover__cardText">
              {supplierId !== null ? totalSuppliersOnboarded : totalUsersWithSupplier}
            </p>
          </Card>
          <Card
            title="Total Supplier Users Onboarded"
            className='dashboardApprover__card'          >
            <p className="m-0 normal-font-color dashboardApprover__cardText">{totalUsersWithSupplier}</p>
          </Card>
          <Card
            title="Total Active Users with Supplier"
            className='dashboardApprover__card'          >
            <p className="m-0 normal-font-color dashboardApprover__cardText">
              {supplierId !== null ? totalActiveUsersWithSupplier : totalUsersWithSupplier}
            </p>
          </Card>
        </div>
        {/* Second row of cards */}
       
  </div>
</div>
  );
};

export default DashboardApprover;