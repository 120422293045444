import axios from "axios";
import { API_HEADER, API_HEADER_MULTIPART,USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, setAccessToken } from "../utils/http";
import { httpGET , httpPOST, httpPUT} from "../utils/http";

export const getSupplierService = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/items/suppliers', { headers });
    callback(res);
} 
catch (error) {
  errorCallback(error);
}
};

export const toggleEnableService = async (data, token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
    "Content-Type": "application/json",
  };
  try {
    const res = await httpPUT('/item/SupplierSendNotificationFlag', data, headers);
    callback(res);
} 
catch (error) {
  errorCallback(error);
}
};

export const pendingEmailService = async (data, token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
      "Content-Type": "application/json",
    };
  
    try {
        const res = await httpPOST('/item/suppliersnotifications/pending/in progress', data, headers);
      callback(res);
} 
catch (error) {
  errorCallback(error);
}
  };

  export const completedEmailService = async (data, token,callback,errorCallback) => {
    const headers = {
      Authorization: `Bearer ${token || getAccessToken()}`,
      "Content-Type": "application/json",
    };
    try {
        const res = await httpPOST('/item/suppliersnotifications/approved/in review', data, headers);
      callback(res);
} 
catch (error) {
  errorCallback(error);
}
  };

