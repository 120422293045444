import React, { useEffect, useState } from "react";
import {
  Routes as RouteGroup,
  Route,
  useNavigate,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./components/Layout";
import Profile from "./pages/Profile";
import ItemsList from "./pages/ItemsList";
import ForgotPassword from "./pages/ForgotPassword";
import ImportSupplier from "./pages/ImportSupplier";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import Storage from "../src/utils/storage";
import {
  PrivateRoute,
  RedirectRoute,
  PublicRoute,
} from "./utils/route-handling";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const user1 = useSelector((store) => ("storedata", store));
  const Role = Storage.get("Role");
  
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const Token = Storage.get("user");

  const checkUserToken = () => {
    const userToken = localStorage.getItem("token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      navigate("/");
    } else {
      setIsLoggedIn(true);
      <Layout setIsLoggedIn={setIsLoggedIn} />;
    }
  };

  return (
    <div className="App">
      {/* { isLoggedIn ? <Layout/> : <Login/>}   */}

      {<PrivateRoute component={<Layout />} />}
      {/* { <ProtectedRoute component={<Layout />}/> } */}
      {!Token && (
        <RouteGroup>
          {/* <Route exact path="/" element={<RedirectRoute component={<Login />}/>}/> */}
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/dashboard" element={<Dashboard/>} />
                <Route exact path="/itemslist" element={<ItemsList/>} /> */}
          <Route path="/register" element={<Register />} />
          {/* <Route path="/register" element={<PublicRoute component={<Register/>}/>} /> */}
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/welcome" element={<Navigate to="/" replace />} />
        </RouteGroup>
      )}

      {/* {login == true ? <Layout /> : <Login />} */}
    </div>
  );
}

export default App;
