import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import { Toast } from "primereact/toast";
import logo from "./../assets/images/logo.png";
import Config from "./../Config";
import Storage from "../utils/storage";
import { registerUser } from "../services/UserServices";
import { emailValidator, firstNameValidator, lastNameValidator } from "../utils/userValidator";
import { showErrorToast, showSuccessToast } from "../services/ToastService";

function Register() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [isvalidFirst, setValidFirst] = useState(false);
  const [isvalidLast, setValidLast] = useState(false);
  const [isvalid, setValid] = useState(false);
  const status = useSelector((store) => store.addUser?.users?.status);
  const errMessage = useSelector((store) => store.addUser?.users?.message);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [count, setCount] = useState(0);
  const [isLoading,setIsLoading]=useState(false);
  const token = Storage.get("accessToken");

  const registerAction = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsLoading(true)

    let payload = {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
    };
    
      registerUser(payload,token,(res) => {
        setIsSubmitting(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setIsLoading(false);
        localStorage.setItem("token", res.data);
        showSuccessToast(toast, "Registered successfully! Please Login");
      },
      (error) => {
        setIsLoading(false);
        showErrorToast(toast, error.response?.data);

        setIsSubmitting(false);
        if (e.response?.data?.errors != undefined) {
          setValidationErrors(e.response?.data?.errors);
        }
      });
  };

  const handleEmail = (e) => {
    
    if (!emailValidator(e.target.value)) {
      setValid(false);
      setEmail(e.target.value);
    } else {
      setEmail(e.target.value);
      setValid(true);
    }
  };

  const handleFirstName = (e) => {
    if (!firstNameValidator(e.target.value)) {
      setValidFirst(false);
      setFirstName(e.target.value);
    } else {
      setFirstName(e.target.value);
      setValidFirst(true);
    }
  };

  const handleLastName = (e) => {
    
    if (!lastNameValidator(e.target.value)) {
      setValidLast(false);
      setLastName(e.target.value);
    } else {
      setLastName(e.target.value);
      setValidLast(true);
    }
  };

  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


  return (
    <div>
      <Toast ref={toast} />
      
      <div className="logout-wraper">
        <div className="login-container">
          <div className="login-logo">
            <img src={logo} alt="Bunzl" className="logo-image"/>
          </div>
          <div className="row justify-content-md-center mt-5">
          <div className="col-10">
            <div className="card" style={{ top: "-86px" }}>
              <div className="card-body">
                <h5 className="card-title mb-4">Register</h5>
                <form onSubmit={registerAction}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => handleFirstName(e)}
                    />
                    {!isvalidFirst && firstName.length !== 0 && (
                      <small className="p-error">Enter valid First Name</small>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => handleLastName(e)}
                    />
                  </div>

                  {!isvalidLast && lastName.length !== 0 && (
                    <small className="p-error">Enter valid Last Name</small>
                  )}

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => handleEmail(e)}
                    />
                    {validationErrors.email != undefined && (
                      <div className="flex flex-col">
                        <small className="text-danger">
                          {validationErrors.email[0]}
                        </small>
                      </div>
                    )}
                    {!isvalid && email.length !== 0 && (
                      <small className="p-error">Enter valid email</small>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      required
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {validationErrors.password != undefined && (
                      <div className="flex flex-col">
                        <small className="text-danger">
                          {validationErrors.password[0]}
                        </small>
                      </div>
                    )}
                  </div>
                  <div className="d-grid gap-2">
                    <button
                      disabled={!isvalid || !isvalidFirst || !isvalidLast}
                      type="submit"
                      className="btn btn-primary btn-block"
                      style={{ backgroundColor: "#003e7e" }}
                    >
                      Register Now
                    </button>
                    <p className="text-center">
                      Have already an account <Link to="/">Login here</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default Register;


