// http Library-- axios, ajax, superagent etc.
import axios from "axios";
import {
  LOGIN_API,
  USERS_BASE_API,
  API_HEADER,
} from "../constants/ApiConstants";
import { decryptData, encryptData } from "./cryptoHelper";
import Storage from "./storage";

export const httpGET = async (url, header) => {
  const data = await axios.get(USERS_BASE_API + url, header);
  return data;
};

export const httpPOST = async (url, body, header) => {
  let apis = USERS_BASE_API + url;
  const data = await axios.post(apis, body, { headers: header });
  return data;
};

export const httpPUT = async (url, body, header) => {
  let apis = USERS_BASE_API + url;
  const data = await axios.put(apis, body, { headers: header });
  return data;
};

export const httpDELETE = async (url, header) => {
  const data = await axios.delete(url, header);
  return data;
};

export const getAccessToken = () => {
  // return decryptData(localStorage.getItem('accessToken'));
  return decryptData(Storage.get("accessToken"));
};

export const setAccessToken = (token) => {
  // localStorage.setItem('accessToken', encryptData(token));
  Storage.set("accessToken", encryptData(token));
};

export const setRefreshToken = (token) => {
  // localStorage.setItem('refreshToken', encryptData(token));
  Storage.set("refreshToken", encryptData(token));
};

export const getRefreshToken = () => {
  // return decryptData(localStorage.getItem('refreshToken'));
  return decryptData(Storage.get("refreshToken"));
};

export function deleteStoredData() {
  Storage.remove("user");
  Storage.remove("accessToken");
  Storage.remove("refreshToken");
  Storage.remove("Role");
  Storage.remove("survey");
  Storage.remove("surveyPassed");

  Storage.remove("selectedMaterialTypeDropdown");
  Storage.remove("selectedMaterialTypeLidDropdown");
  Storage.remove("selectedPrimaryCategoryDropdown");
  Storage.remove("selectedColorsDropdown");
  Storage.remove("selectedAS400ColorsDropdown");
  Storage.remove("selectedCertificationsDropdown");
  Storage.remove("selectedHFMCodeDropdown");
  Storage.remove("selectedCompostableDropdown");
  Storage.remove("selectedReusableDropdown");
  Storage.remove("selectedProductTypeLidDropdown");
  Storage.remove("secondaryCategories");
  Storage.remove("subCategories");
  Storage.remove("materialSubTypes");
}

export function registerInterceptor() {
  axios.interceptors.request.use(function (config) {
    // Do not get access token if login API fails with code 401
    config.headers.__retry =
      config.data?.__AccessToken || config.url.indexOf(LOGIN_API) !== -1
        ? 0
        : 1;
    config.headers.Authorization =
      "Bearer " +
      (config.data?.__AccessToken ? getRefreshToken() : getAccessToken());

    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    function (error) {
      if (
        error.response?.status === 401 &&
        error.response.config.headers.__retry
      ) {
        return httpPOST("/user/accessToken", {
          __AccessToken: true,
        })
          .then((response) => {
            setAccessToken(response.data.data.accessToken);
            return axios(error.response.config);
          })
          .catch((e) => {
            // console.error(e);
            deleteStoredData();
            window.location = "/";
            return Promise.reject(e);
          });
      } else {
        return Promise.reject(error);
      }
    }
  );
}

