import CryptoJS from "crypto-js";
import { decode, encode } from "base-64";
const encryptionKey = "my-secret-key@246";
export const encryptData = (data) => {
  try {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      //   process.env.REACT_APP_PRIVATE_KEY ||
      encryptionKey
    ).toString();

    return cipherText;
  } catch (e) {
    // console.error(e.message);
    return "";
  }
};

export const decryptData = (ciphertext) => {
  if (!ciphertext) {
    return "";
  }
  try {
    const bytes = CryptoJS.AES.decrypt(
      ciphertext,
      //   process.env.REACT_APP_PRIVATE_KEY ||
      encryptionKey
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
   
    return decryptedData;
  } catch (error) {
    // console.error(error.message);
    return "";
  }
};

export const base64Encode = (data) => {
  return encode(data);
};
export const base64Decode = (data) => {
  return decode(data);
};
