import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./../App.css";
import logo from "./../assets/images/logo.png";
import Config from "./../Config";
import { Toast } from "primereact/toast";
import { getAccessToken } from "../utils/http";
import axios from "axios";
import { handleSubmits } from "../services/UserServices";
import { showErrorToast, showSuccessToast } from "../services/ToastService";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const toast = useRef(null);
  const token = getAccessToken();

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      
        Email: email,
        Id: "",
        UserName: email, 
        Token: null,
        Password:""
       };
      handleSubmits(payload,token,(res) => {
        setEmail("");
        localStorage.setItem("token", res.data);
        showSuccessToast(toast, 
          "Password Link sent to your email address. Kindly check spam if needed.", 
          4000);
      },
      (error) => {
        showErrorToast(toast, "Enter a valid registered email address.", 4000);
      });
      setEmail("");
  };

  return (
    <div className="login-wraper">
        <div className="login-container">
          <div className="login-logo">
            <img src={logo} alt="Bunzl" className="logo-image"/>
          </div>
          <div className="row justify-content-md-center mt-5">
            <div className="col-10">
              <div className="card" style={{ top: "-86px" }}>
                <div className="card-body">
                <h2 className="text-center" style={{ color: "#003e7e" }}>
                  Reset Password
                </h2>
                <div autocomplete="off" className="form">
                  <br />
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        placeholder="Email Address"
                        className="form-control"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                 <br/>
                  <div className="form-group  text-center">
                    <input
                      className="btn btn-lg btn-primary btn-block"
                      value="Submit"
                      type="button"
                      style={{ backgroundColor: "#003e7e" }}
                      onClick={handleSubmit}
                    />
                    <br />
                    <br />
                    <input
                      className="btn btn-secondary"
                      value="Back To Login"
                      type="button"
                      onClick={() => navigate("/")}
                    />
                  </div>
                  <Toast ref={toast} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
