export const DO_LOGIN = 'DO_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS='CLEAR_ERRORS'

export const DO_ADD_USER = 'DO_ADD_USER'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILED = 'ADD_USER_FAILED'

