import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../store/actions";
import Storage from "../utils/storage";
import {  
  deleteStoredData  
} from "../utils/http";

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const logoutAction = () => {
    dispatch({
      type: LOGOUT,
    });
    Storage.remove("Role");
    deleteStoredData();
  };

  return (
    <div className="d-flex">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            to={"/"}
            onClick={() => logoutAction()}
            className="nav-link "
            aria-current="page"
            href="#"
          >
            <u>Logout</u>
          </Link>
        </li>
      </ul>
    </div>
  );
}
export default Logout;
