const dev = {
  apiBaseAddress: "https://bunzl-apim-prod.azure-api.net",
  SubscriptionKey:'3ad20be2928944a99aa3df6d0df8f88f' 
};

const release = {
  apiBaseAddress: "https://bunzl-apim-prod.azure-api.net",
  SubscriptionKey:'3ad20be2928944a99aa3df6d0df8f88f' 
};

const uat = {};

const prod = {
// apiBaseAddress: "https://bunzl-apim-dev.azure-api.net",
// SubscriptionKey:'ee8f918e80044d7e960acad537e863ec'
};

let Config;
switch (process.env.REACT_APP_ENVIRONMENT) {
case "Development":
  Config = dev;
  break;

case "Release":
  Config = release;
  break;

case "UAT":
  Config = uat;
  break;

case "Production":
  Config = prod;
  break;

default:
  Config = release;
  break;
}

export default Config;
