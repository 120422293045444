export const emailValidator = (input) =>
{
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input);
}

export const firstNameValidator = (input) => 
{
  return /^(?![0-9 \-']+$)[A-Z0-9 \-']{2,}$/i.test(input);
}

export const lastNameValidator = (input) =>
{
  return /^(?![0-9 \-']+$)[A-Z0-9 \-']{1,}$/i.test(input);
}

export const passwordValidator = (input) => 
{ 
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#%&`~_=;':",<>\$\^\*\(\)\-\+\[\]\\\{\}\|\.\/\?]).{8,15}$/.test(input);
}

export const PASSWORD_REQUIREMENTS = "Password should be minimum 8 characters long with 1 uppercase, 1 lowercase, 1 special character and 1 number.";
