import axios from 'axios'


const  register = async(data) => {
    console.log("data", data);
    let payload = data.data
    try {
    
   const data = await axios.post('https://mock-api.binaryboxtuts.com/api/register',payload, 
   {
    headers:{
        'Content-Type':'application/json',
    },
   })
   return data
//    console.log("datax :-", data);
} catch(e){
 return e
}
    
}
 
export default register;