import * as actions from '../actions'

const initialState = {
    users: {},
    loading: false,
    error: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.DO_ADD_USER:
        return { ...state, loading: true };
      case actions.ADD_USER_SUCCESS:
        return { ...state, loading: false, users: action.users };
      case actions.ADD_USER_FAILED:
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default userReducer;