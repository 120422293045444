const Role = 
{
  ADMIN: "admin",
  SUPPLIER: "supplier",
  VIEWER: "viewer",
  APPROVER: "approver",
  INTERNAL_ENRICHER: "Internal Enricher",
  EXTERNAL_ENRICHER: "External Enricher",
  INTERNAL_QUALITY:"Internal Quality Assurance",
  EXTERNAL_QUALITY:"External Quality Assurance",
  ESG: "ESG",
  EXTERNAL_AUDITOR: "External Auditor"
};
export function isAdmin(role)
{
  return role?.toLowerCase() === Role.ADMIN;
}

export function isSupplier(role)
{
  return role?.toLowerCase() === Role.SUPPLIER;
}
export function isViewer(role)
{
  return role?.toLowerCase() === Role.VIEWER;
}
export function isApprover(role)
{
  return role?.toLowerCase() === Role.APPROVER;
}
export function isApproverOrViewer(role)
{
  return isApprover(role) || isViewer(role) ;
}
export function isInternalEnricher(role)
{
  return role === Role.INTERNAL_ENRICHER;
}
export function isExternalEnricher(role)
{
  return role === Role.EXTERNAL_ENRICHER;
}
export function isEnricher(role)
{
  return isInternalEnricher(role) || isExternalEnricher(role);
}
export function isQuality(role)
{
  return isInternalQuality(role) || isExternalQuality(role);
}
export function isESG(role) {
  return role === Role.ESG;
}
export function isNotAnyRole(role)
{
  return !isAdmin(role) && !isSupplier(role) && ! isApprover(role) && !isViewer(role) && !isInternalEnricher(role) && !isExternalEnricher(role) && !isQuality(role) && !isInternalQuality(role) && !isExternalQuality(role) && !isESG(role) && !isExternalAuditor(role);
}
export function isInternalQuality(role)
{
  return role === Role.INTERNAL_QUALITY;
}
export function isExternalQuality(role)
{
  return role === Role.EXTERNAL_QUALITY;
}
export function isExternalAuditor(role)
{
  return role === Role.EXTERNAL_AUDITOR;
}