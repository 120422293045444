import axios from "axios";
import { API_HEADER, USERS_BASE_API } from "../constants/ApiConstants";
import { getAccessToken, getRefreshToken, httpPOST, setAccessToken } from "../utils/http";
import { httpGET , httpPUT} from "../utils/http";

export const updateItemHfm = async (emptyProduct,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpPUT('/item/UpdateItemHfm', emptyProduct, headers);
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const ColorsDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/colors', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const AS400ColorsDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/AS400Colors', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const CertificationsDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/Certifications', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const materialTypesDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/materialType', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const materialSubTypeDropdown = async (selectedMaterialType,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/GetMaterialSubTypes/${selectedMaterialType}`, { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const materialTypeLidDropdown = async (token) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/item/materialTypeLid', { headers });
    return res.data;
  } 
  catch (error) {
    throw error;
  }
};
export const materialSubTypeLidDropdown = async (selectedMaterialTypeLid,token) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/item/GetMaterialSubTypesLid/${selectedMaterialTypeLid}`, { headers });
    return res.data;
  } 
  catch (error) {
    throw error;
  }
};

export const primaryCategoryDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/category/primary', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const secondaryCategoryDropdown = async (selectedPrimaryCategory,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/category/secondary/${selectedPrimaryCategory}`, { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const hfmCodeDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/HFM/HFMCode', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const hfmSubCodeDropdown = async (selectedHFMCode,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/HFM/HFMSubCode/${selectedHFMCode?.Id}`, { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const hfmFinalCodeDropdown = async (selectedHFMSubCode,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/HFM/HFMSubCode/${selectedHFMSubCode?.Id}`, { headers });
    
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const secondarySubCategoryDropdown = async (selectedSecondaryCategory,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/category/secondarysub/${selectedSecondaryCategory}`, { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const subCategoryDropdown = async (selectedSecondaryCategory,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/category/subcategory/${selectedSecondaryCategory}`, { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const compostableDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/compostable', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const reusableDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/reusableAndRefillable', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const productTypeItemLidDropdown = async (token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET('/productTypeItemOrLid', { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const itemList = async (body, token, callback, errorCallback) => {
  const headers = {
    ...API_HEADER,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpPOST("/items/allpaginatedsummary", body, headers);
    callback(res);
  } catch (error) {
    errorCallback(error);
  }  
};

export const saveProducts = async (emptyProduct,token) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpPUT('/item/UpdateItemHfm', emptyProduct, headers);
    return res.data;
  } 
  catch (error) {
    throw error;
  }
};

export const viewProductsDetail = async (itemId,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/GetItem/${itemId}`, { headers });
    callback(res);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const validateProducts = async (emptyProduct,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpPUT('/item/review', emptyProduct, headers);
    callback(res);
} 
catch (error) {
  errorCallback(error);
}
};

export const errorHandleProducts = async (emptyProduct,token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpPUT('/item/review', emptyProduct, headers);
    callback(res);
} 
catch (error) {
  errorCallback(error);
}
};

export const GetItemAudit = async (itemId, token,callback,errorCallback) => {
  const headers = {
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET(`/item/GetItemAudit/${itemId}`, { headers });
    callback(res.data);
  } 
  catch (error) {
    errorCallback(error);
  }
};

export const itemMappingsList = async (token, callback, errorCallback) => {
  const headers = {
    ...API_HEADER,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token || getAccessToken()}`,
  };
  try {
    const res = await httpGET("/items/itemmanagement", { headers });
    callback(res);
  } catch (error) {
    errorCallback(error);
  }  
};
